import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import {
  PickersLayoutProps,
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from '@mui/x-date-pickers/PickersLayout';
import { DateView } from '@mui/x-date-pickers/models';
import {
  DatePicker,
  DatePickerProps,
  DatePickerToolbarProps,
  PickersCalendarHeaderProps,
} from '@mui/x-date-pickers';
import { LBTButton } from '../Button';
import { Grid, Stack, styled } from '@mui/material';
import LBTButtonIcon from '../ButtonIcon';
import { IconArrowLeftComponent, IconArrowRightComponent } from '../Icons';
import { IconsSize, IconsStyle } from '../../enums';
import { COLORS } from '../../utils';
import LBTLabel from '../Label';
import 'dayjs/locale/it';
import { DATETIME_FORMAT } from '@laborability/commons';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function CustomHeader(props: PickersCalendarHeaderProps<Dayjs>) {
  const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
  });

  const { currentMonth, onMonthChange, onViewChange } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month'), 'left');
  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, 'month'), 'right');

  return (
    <CustomCalendarHeaderRoot>
      <Stack spacing={1} direction="row">
        <LBTButtonIcon onClick={selectPreviousMonth}>
          <IconArrowLeftComponent
            style={IconsStyle.FILLED}
            size={IconsSize.SMALL}
            color={COLORS.getInstance().PRIMARY_MAIN}
          />
        </LBTButtonIcon>
      </Stack>
      <LBTButton
        variant="invisible"
        color="primary"
        onClick={() => {
          if (onViewChange) onViewChange('year');
        }}
      >
        {capitalizeFirstLetter(currentMonth.format('MMMM YYYY'))}
      </LBTButton>
      <Stack spacing={1} direction="row">
        <LBTButtonIcon onClick={selectNextMonth}>
          <IconArrowRightComponent
            style={IconsStyle.FILLED}
            size={IconsSize.SMALL}
            color={COLORS.getInstance().PRIMARY_MAIN}
          />
        </LBTButtonIcon>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

function CustomActions(props: PickersActionBarProps) {
  const { onAccept, onCancel } = props;

  return (
    <Grid container sx={{ paddingBottom: '32px', paddingRight: '24px' }}>
      <Grid item mobile={12} desktop={12}>
        <Grid container justifyContent="right">
          <Grid item mobile={8} desktop={12}>
            <Stack direction={'row'} spacing="8px">
              <LBTButton
                size="small"
                variant="invisible"
                fullWidth
                onClick={onCancel}
              >
                Annulla
              </LBTButton>
              <LBTButton
                size="small"
                variant="outlined"
                fullWidth
                onClick={onAccept}
              >
                Conferma
              </LBTButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CustomToolbar(props: DatePickerToolbarProps<Dayjs>) {
  return (
    <Stack className={props.className} sx={{ padding: '15px 24px 15px 24px' }}>
      <LBTLabel
        color={`${COLORS.getInstance().BLACK}99`}
        textAlign="left"
        variant={'datepickerTitle'}
      >
        SELEZIONA UNA DATA
      </LBTLabel>
      <LBTLabel
        sx={{ minHeight: '40px' }}
        color={`${COLORS.getInstance().BLACK}E5`}
        textAlign="left"
        variant={'datepickerSubtitle'}
      >
        {props.value?.format('DD MMM, YYYY')}
      </LBTLabel>
    </Stack>
  );
}

function CustomLayout(
  props: PickersLayoutProps<Dayjs | null, Dayjs, DateView>,
) {
  const { toolbar, content, actionBar } = usePickerLayout(props);

  return (
    <PickersLayoutRoot ownerState={props}>
      {toolbar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {content}
        {actionBar}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

interface Props
  extends Omit<DatePickerProps<Dayjs>, 'value' | 'minDate' | 'maxDate'> {
  value?: Dayjs | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  label: string;
  handleChange: (val?: Dayjs) => void;
}

export default function LBTDatePicker({
  value,
  label,
  handleChange,
  minDate,
  maxDate,
  disabled,
  ...props
}: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
        label={label}
        value={value ? value : value === null ? null : undefined}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : undefined}
        onChange={val => handleChange(val ? val : undefined)}
        slotProps={{
          toolbar: {
            toolbarFormat: 'DD MMM YYYY',
          },
          textField: {
            InputProps: {
              style: {
                backgroundColor: disabled
                  ? COLORS.getInstance().BW_GREYS_IPERLIGHT
                  : undefined,
              },
            },
          },
        }}
        slots={{
          calendarHeader: CustomHeader,
          layout: CustomLayout,
          actionBar: CustomActions,
          toolbar: CustomToolbar,
        }}
        sx={{
          width: '100%',
        }}
        disabled={disabled}
        {...props}
      />
    </LocalizationProvider>
  );
}
