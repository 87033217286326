import { CallbackInterface } from 'recoil';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import {
  GetActionRule,
  Id,
  Post,
  ActionRule,
  Put,
  QuestionRule,
  GetQuestionRule,
} from '../types';
import {
  actionRuleLoaderState,
  actionRuleState,
  actionRulesLoaderState,
  actionRulesState,
  questionLoaderState,
  questionRuleLoaderState,
  questionRuleState,
  questionRulesState,
  questionsLoaderState,
} from '../state';
import {
  postActionRule,
  getActionRuleById,
  getAllActionRule,
  putActionRule,
  deleteActionRule,
  postQuestionRule,
  deleteQuestionRule,
  getAllQuestionRule,
  putQuestionRule,
  getQuestionRuleById,
} from '../actions';

export const postActionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<ActionRule>) => {
    set(actionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await postActionRule(params);
      set(actionRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione creata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(actionRuleLoaderState, false);
    return res;
  };

export const postQuestionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<QuestionRule>) => {
    set(questionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await postQuestionRule(params);
      set(questionRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione creata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionRuleLoaderState, false);
    return res;
  };

export const getActionRuleByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetActionRule) => {
    set(actionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await getActionRuleById(params);
      set(actionRuleState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(actionRuleLoaderState, false);
    return res;
  };

export const getQuestionRuleByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetQuestionRule) => {
    set(questionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionRuleById(params);
      set(questionRuleState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionRuleLoaderState, false);
    return res;
  };

export const getAllActionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(actionRulesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllActionRule(params);
      set(actionRulesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(actionRulesLoaderState, false);
    return res;
  };

export const getAllQuestionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllQuestionRule(params);
      set(questionRulesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionsLoaderState, false);
    return res;
  };

export const putActionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<ActionRule>) => {
    set(actionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await putActionRule(params);
      set(actionRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(actionRuleLoaderState, false);
    return res;
  };

export const putQuestionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<QuestionRule>) => {
    set(questionLoaderState, true);
    let res = undefined;
    try {
      res = await putQuestionRule(params);
      set(questionRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionLoaderState, false);
    return res;
  };

export const deleteActionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetActionRule) => {
    set(actionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await deleteActionRule(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(actionRuleLoaderState, false);
    return res;
  };

export const deleteQuestionRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetQuestionRule) => {
    set(questionRuleLoaderState, true);
    let res = undefined;
    try {
      res = await deleteQuestionRule(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionRuleLoaderState, false);
    return res;
  };
