import { ButtonProps } from '@mui/material';
import React from 'react';
import LBTRadio from '../Radio';
import LBTLabel from '../Label';
import { COLORS } from '../../utils';
import { StyledBox } from './Button';

export type LBTRadioButtonProps<T> = ButtonProps & {
  icon?: React.ReactNode;
  label: string;
  value: T;
  currentValue: T;
  handleChange: (val: T) => void;
  datatestid?: string;
};

export default function LBTRadioButton<T>({
  label,
  value,
  currentValue,
  handleChange,
  datatestid = '',
  ...props
}: LBTRadioButtonProps<T>) {
  return (
    <StyledBox
      datatestid={`lbt-select-${datatestid}`}
      data-track={datatestid}
      onClick={() => handleChange(value)}
      sx={{
        backgroundColor:
          currentValue === value
            ? COLORS.getInstance().PRIMARY_IPERLIGHT
            : COLORS.getInstance().WHITE,
      }}
      {...props}
    >
      <LBTLabel variant="inputFormLabel">{label}</LBTLabel>
      <LBTRadio value={value} onChange={() => {}} />
    </StyledBox>
  );
}
