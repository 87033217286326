import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  BackdropLoader,
  IconAssocaaf,
  IconSuperhero,
  LBTAlert,
  LBTButton,
  LBTDivider,
  LBTLabel,
  LBTSpacer,
  ProgressIndicatorWidget,
  QuestionaryContext,
  Section,
} from '..';
import {
  QUESTIONARY_STEPS,
  QuestionFlow,
  continueFlowCallback,
  evalActionCallback,
  continueFlowState,
  useBreakpoint,
  STATIC_QUESTIONARY_NUMBER,
  updateVisibilityCallback,
  QUESTION_TYPES_ENUM,
  DestinationTypeEnum,
  Question as QuestionType,
  ContinueFlowQuestion,
  slugify,
  useTrackAnalytics,
  ActionTypeEnum,
} from '@laborability/commons';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../utils';
import Question, { ListValue, Value } from './Question';
import Action from './Action';
import { Box, Link } from '@mui/material';
import BoldSpan from './BoldSpan';

export interface Answer {
  question_id: number;
  entity_id: number;
  answer_value: Value;
}

interface Props {
  flow: QuestionFlow;
  isDemo?: boolean;
}

export function findAnswer(
  values: Answer[],
  qId: number,
  eId: Number,
): Answer | undefined {
  return values.find(
    value => value.question_id === qId && value.entity_id === eId,
  );
}

export default function Page({ flow, isDemo = false }: Props) {
  const { isDesktop } = useBreakpoint();
  const { pageId } = useContext(QuestionaryContext);
  const pageData = useRecoilValue(continueFlowState);
  const [questionsLoader, setQuestionsLoader] = useState<number>(0);
  const pageNumber = pageData.position ?? 1;

  useTrackAnalytics(
    flow.name
      ? [
          { key: 'event', value: 'page_view' },
          {
            key: 'page_title',
            value: `Questionario - ${flow.name} - Step ${pageNumber}`,
          },
          {
            key: 'page_location',
            value: `/onboarding/${slugify(flow.name)}/${pageNumber}`,
          },
        ]
      : [],
  );

  useTrackAnalytics(
    flow.number_of_pages === pageNumber && flow.name
      ? [
          { key: 'event', value: 'questionario' },
          { key: 'questionario', value: `questionario-${slugify(flow.name)}` },
        ]
      : [],
  );

  return (
    <>
      {!!questionsLoader && <BackdropLoader />}
      <Section
        style={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
          minHeight: 'calc(100vh - 200px)',
          height: '100%',
        }}
      >
        <Stepper flow={flow} pageNumber={pageNumber} />
        {pageId ? (
          <QuestionSection
            flowId={flow.id!}
            setQuestionsLoader={setQuestionsLoader}
            isDemo={isDemo}
          />
        ) : (
          <div />
        )}
        <div style={{ display: 'flex', flex: 1, paddingRight: '64px' }} />
      </Section>
      {pageId && <MediaSection />}
      {pageId && <AssocaafSection />}
    </>
  );
}

interface QuestionSectionProps {
  flowId: number;
  setQuestionsLoader: React.Dispatch<React.SetStateAction<number>>;
  isDemo: boolean;
}

function QuestionSection({
  flowId,
  setQuestionsLoader,
  isDemo,
}: QuestionSectionProps) {
  const { isDesktop } = useBreakpoint();
  const { pageId, setPageId, setStep } = useContext(QuestionaryContext);
  const pageData = useRecoilValue(continueFlowState);
  const [values, setValues] = useState<Answer[]>([]);
  const [errors, setErrors] = useState<number[]>([]);
  const changeVisibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const getPage = useRecoilCallback(continueFlowCallback, []);
  const evalAction = useRecoilCallback(evalActionCallback, []);
  const updateVisibility = useRecoilCallback(updateVisibilityCallback, []);
  const actions = pageData.actions;
  const thereIsSkip = actions.some(
    action => action.action_type === ActionTypeEnum.skip,
  );

  const question = pageData.questions;
  const autoAction = actions?.find(item => item.action_type === 'auto');
  const requiredQuestions =
    question.filter(item => item.question.required && item.question.visible) ??
    [];
  const hasQuestionRules = pageData.has_question_rules;
  const helpTip = pageData?.tips?.find(item => item.type === 'help');
  const entertainmentTip = pageData?.tips?.find(
    item => item.type === 'entertainment',
  );

  const isButtonDisabled = () => {
    if (errors.length) return true;
    for (let i = 0; i < requiredQuestions.length; i++)
      if (
        !findAnswer(
          values,
          requiredQuestions[i].question_id,
          requiredQuestions[i].entity_id,
        )?.answer_value
      )
        return true;
    return false;
  };

  const evalButtonAction = async (action_id: number, values: Answer[]) => {
    if (isDemo) return;
    const res = await evalAction({
      flow_id: flowId,
      page_id: pageId,
      action_id,
      questions: values.filter(
        value =>
          value.answer_value !== null &&
          question.find(
            item =>
              item.question_id === value.question_id &&
              item.entity_id === value.entity_id,
          )?.question.visible,
      ),
    });
    if (res?.data?.type === DestinationTypeEnum.menu) {
      setPageId(0);
      setStep(0);
      setValues([]);
    } else if (res?.data?.type === DestinationTypeEnum.next_step) {
      setPageId(0);
      setStep(res.data?.destination_step ?? 0);
      setValues([]);
    } else if (res?.data?.target_page_id) {
      setPageId(res.data.target_page_id);
      setValues([]);
    }
  };

  const changeQuestionsVisibility = async (values: Answer[]) => {
    const res = await updateVisibility({
      flow_id: flowId,
      page_id: pageId,
      questions: values.filter(
        value =>
          value.answer_value !== null &&
          (!(value.answer_value as ListValue)?.type ||
            !!(value.answer_value as ListValue)?.value?.length),
      ),
    });

    // elimina le risposte alle domande non più visibili
    const visibleQuestions = res?.data?.questions?.filter(
      (item: Answer & { question: QuestionType }) => item.question.visible,
    );
    if (visibleQuestions)
      setValues(
        values.filter(value =>
          visibleQuestions.find(
            (item: Answer) =>
              item.question_id === value.question_id &&
              item.entity_id === value.entity_id,
          ),
        ),
      );
  };

  const getNextPage = async () => {
    const res = await getPage({ flow_id: flowId, page_id: pageId });
    if (res?.data) {
      setValues(
        res.data?.questions
          ?.filter((item: ContinueFlowQuestion) => item.question.visible)
          ?.map((item: ContinueFlowQuestion) => ({
            question_id: item.question_id,
            entity_id: item.entity_id,
            answer_value: item.answer_value,
          })) ?? [],
      );
    }
  };

  useEffect(() => {
    if (flowId && pageId) getNextPage();
  }, [flowId, pageId]);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: isDesktop ? '680px' : '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'flex-start',
      }}
    >
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay">{pageData.name}</LBTLabel>
      {pageData?.description && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle">
            {pageData.description}
          </LBTLabel>
        </>
      )}
      {pageData?.hint && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">{pageData.hint}</LBTLabel>
        </>
      )}
      <LBTSpacer spacing={4} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {question.map(item => {
          if (!item.question.visible) return null;
          return (
            <Fragment key={`${item.question_id}_${item.entity_id}`}>
              <Question
                question={item.question}
                questions={question}
                value={
                  findAnswer(values, item.question_id, item.entity_id)
                    ?.answer_value
                }
                values={values}
                handleChange={(value: Value) => {
                  // update current values
                  let isUpdated = false;
                  let newValues = values.reduce((prev: Answer[], current) => {
                    if (
                      current.question_id === item.question_id &&
                      current.entity_id === item.entity_id
                    ) {
                      isUpdated = true;
                      return [
                        ...prev,
                        {
                          ...current,
                          answer_value: value,
                        },
                      ];
                    }
                    return [...prev, current];
                  }, []);
                  if (!isUpdated)
                    newValues = [
                      ...newValues,
                      {
                        question_id: item.question.id!,
                        entity_id: item.entity_id,
                        answer_value: value,
                      },
                    ];

                  // eval auto action
                  if (autoAction) {
                    for (let i = 0; i < requiredQuestions.length; i++) {
                      let answer = findAnswer(
                        newValues,
                        requiredQuestions[i].question_id,
                        requiredQuestions[i].entity_id,
                      )?.answer_value;
                      if (
                        answer === undefined ||
                        answer === '' ||
                        answer === null
                      )
                        return;
                    }

                    evalButtonAction(autoAction.id!, newValues);
                  }
                  // change question visibility
                  else if (hasQuestionRules) {
                    if (changeVisibilityTimeoutRef.current)
                      clearTimeout(changeVisibilityTimeoutRef.current);
                    if (
                      item.question.question_type ===
                        QUESTION_TYPES_ENUM.text ||
                      item.question.question_type === QUESTION_TYPES_ENUM.date
                    )
                      changeVisibilityTimeoutRef.current = setTimeout(
                        () => changeQuestionsVisibility(newValues),
                        1000,
                      );
                    else changeQuestionsVisibility(newValues);
                  } else setValues(newValues);
                }}
                setLoader={setQuestionsLoader}
                setError={setErrors}
              />
              <LBTSpacer spacing={4} isFixed />
            </Fragment>
          );
        })}
        {helpTip && (
          <>
            <LBTAlert
              title={helpTip.title}
              message={helpTip.message}
              variant="standard"
              color="help"
            />
            <LBTSpacer spacing={4} />
          </>
        )}
        {actions?.map(action => {
          const isSkipAction = action.action_type === ActionTypeEnum.skip;
          const buttonStyle = action.action_meta?.button_style;
          const variant =
            buttonStyle === 'outlined' || buttonStyle === 'contained'
              ? buttonStyle
              : 'contained';
          const shouldRenderSkip =
            thereIsSkip && isButtonDisabled() && isSkipAction;
          const shouldRenderAction = thereIsSkip
            ? !isButtonDisabled() && !isSkipAction
            : true;

          if (shouldRenderSkip) {
            return (
              <Fragment key={action.id}>
                <LBTButton
                  variant="outlined"
                  onClick={() => evalButtonAction(action.id!, values)}
                  fullWidth
                  size="large"
                >
                  {action.name}
                </LBTButton>
                <LBTSpacer spacing={4} />
              </Fragment>
            );
          }

          if (shouldRenderAction) {
            return (
              <Fragment key={action.id}>
                <Action
                  variant={variant}
                  action={action}
                  evalAction={() => evalButtonAction(action.id!, values)}
                  isButtonDisabled={isButtonDisabled()}
                />
                <LBTSpacer spacing={4} />
              </Fragment>
            );
          }
        })}
        {entertainmentTip && (
          <>
            <LBTAlert
              title={entertainmentTip.title}
              message={entertainmentTip.message}
              variant="standard"
              color="entertainment"
            />
            <LBTSpacer spacing={4} />
          </>
        )}
      </Box>
    </div>
  );
}

function MediaSection() {
  const pageData = useRecoilValue(continueFlowState);

  if (!pageData?.media_title || !pageData?.media_link) return null;

  return (
    <Section
      backgroundColor={COLORS.getInstance().PRIMARY_IPERLIGHT}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay">{pageData.media_title}</LBTLabel>
      {pageData?.media_subtitle && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle">
            {pageData?.media_subtitle}
          </LBTLabel>
        </>
      )}
      {pageData?.media_description && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">{pageData.media_description}</LBTLabel>
        </>
      )}
      <LBTSpacer spacing={4} />
      {pageData.media_link}
      <LBTSpacer spacing={2} />
    </Section>
  );
}

function AssocaafSection() {
  const pageData = useRecoilValue(continueFlowState);

  if (!pageData?.flag_assocaf) return null;

  return (
    <Section style={{ display: 'flex', alignItems: 'center' }}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" component="h3">
        Ti serve aiuto per fare domanda?
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h4">
        Assocaaf richiede per te l’Indicatore della Situazione Economica
        Equivalente (ISEE)
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <IconAssocaaf />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText">
        Assocaaf Spa è un nostro partner, ed è il centro di assistenza fiscale
        costituito dalle associazioni territoriali e di categoria di
        Confindustria e da oltre mille imprese nazionali e multinazionali
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <div style={{ maxWidth: '680px', width: '100%' }}>
        <LBTLabel variant="smallCapsBold" component="h5" textAlign="left">
          Costi
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <ul
          style={{
            margin: 0,
            padding: 0,
            borderTop: `2px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
          }}
        >
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
              borderBottom: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Prima DSU
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              0€
            </LBTLabel>
          </li>
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
              borderBottom: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Presentazioni successive con variazione dei componenti del nucleo
              familiare
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              0€
            </LBTLabel>
          </li>
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Richieste ed elaborazioni successive, per lo stesso nucleo e anno
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              20€
            </LBTLabel>
          </li>
        </ul>
      </div>
      <LBTSpacer spacing={2} />
      <LBTDivider />
      <LBTSpacer spacing={4} />
      <LBTLabel variant="bodyText">
        Per farti aiutare, scrivi a{' '}
        <Link
          href="mailto:info@assocaaf.it"
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        >
          <BoldSpan>info@assocaaf.it</BoldSpan>
        </Link>
      </LBTLabel>
      <LBTSpacer spacing={4} />
    </Section>
  );
}

interface StepperProps {
  flow: QuestionFlow;
  pageNumber: number;
}

function Stepper({ flow, pageNumber }: StepperProps) {
  const { isDesktop } = useBreakpoint();
  return (
    <div
      style={
        isDesktop
          ? {
              position: 'sticky',
              top: '100px',
              alignItems: 'flex-end',
              alignSelf: 'flex-start',
              height: '680px',
              maxHeight: '680px',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              paddingRight: '64px',
            }
          : {
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }
      }
    >
      <LBTSpacer spacing={2} />
      <ProgressIndicatorWidget
        label={flow?.name as string}
        step={flow?.step!}
        stepsNumber={QUESTIONARY_STEPS}
        item={{
          currentValue: pageNumber,
          maxValue: flow.number_of_pages ?? 1,
        }}
        orientation={isDesktop ? 'vertical' : 'horizontal'}
      />
      {isDesktop && <LBTSpacer spacing={2} />}
    </div>
  );
}

interface FlowFinalPageProps {
  step: number;
}

function FlowFinalPage({ step }: FlowFinalPageProps) {
  const getMessage = () => {
    if (step <= STATIC_QUESTIONARY_NUMBER)
      return (
        <>
          <LBTLabel variant="delaDisplay" component="h1">
            Hai diritto a x bonus
          </LBTLabel>
          <LBTSpacer spacing={2} />
          {true && (
            <>
              <LBTLabel variant="spGroteskSubtitle" component="h2">
                Ci sono già delle agevolazioni per te in base alle risposte che
                hai dato fin qui. Continua a esplorare per sbloccarle tutte
              </LBTLabel>
              <LBTSpacer spacing={2} />
            </>
          )}
          <IconSuperhero />
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Salviamo le agevolazioni nella tua area personale: puoi consultarle
            in ogni momento dal menù in alto a destra, nella sezione Le tue
            agevolazioni
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTButton variant="contained" size="large" onClick={() => {}}>
            Sblocca tutte le agevolazioni
          </LBTButton>
        </>
      );
    if (step <= STATIC_QUESTIONARY_NUMBER) return null;
    if (step <= STATIC_QUESTIONARY_NUMBER) return null;
  };

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTSpacer spacing={4} />
      <LBTDivider />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText" component="h4">
        Agevolazioni a cui hai diritto:
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTSpacer spacing={4} />
      <LBTDivider />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText" component="h4">
        Agevolazioni a cui non hai diritto:
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTSpacer spacing={6} />
    </Section>
  );
}
