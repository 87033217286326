import { CallbackInterface } from 'recoil';
import {
  Post,
  AttributeMeta,
  GetAttributesMetaByEntityType,
  Id,
  Put,
} from '../types';
import {
  postAttributeMeta,
  getAttributesMetaByEntityType,
  getAttributeMetaById,
  putAttributeMeta,
} from '../actions';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import {
  attributeMetaLoaderState,
  attributeMetaState,
  attributesMetaLoaderState,
  attributesMetaState,
} from '../state';

export const postAttributeMetaCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<AttributeMeta>) => {
    set(attributeMetaLoaderState, true);
    let res = undefined;
    try {
      res = await postAttributeMeta(params);
      set(attributeMetaState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Record caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(attributeMetaLoaderState, false);
    return res;
  };

export const getAttributeMetaByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(attributeMetaLoaderState, true);
    let res = undefined;
    try {
      res = await getAttributeMetaById(params);
      set(attributeMetaState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(attributeMetaLoaderState, false);
    return res;
  };

export const getAttributesMetaByEntityTypeCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetAttributesMetaByEntityType) => {
    set(attributesMetaLoaderState, true);
    let res = undefined;
    try {
      res = await getAttributesMetaByEntityType(params);
      set(attributesMetaState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(attributesMetaLoaderState, false);
    return res;
  };

export const putAttributeMetaCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<AttributeMeta>) => {
    set(attributeMetaLoaderState, true);
    let res = undefined;
    try {
      res = await putAttributeMeta(params);
      set(attributeMetaState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(attributeMetaLoaderState, false);
    return res;
  };
