import { Box, ListItem, ListItemProps, ListItemText } from '@mui/material';
import { COLORS } from '../../utils';
import LBTLabel, { LBTTypographyStyles } from '../Label';
import styled from 'styled-components';
import { CSSProperties, ReactNode } from 'react';

const StyledListItem = styled(ListItem)<
  ListItemProps & { datatestid?: string; 'data-track'?: string }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],

  alignItems: 'flex-start',
  '&hover': {
    backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
  },
}));

interface Props extends ListItemProps {
  title: string;
  description?: string;
  titleStyle?: CSSProperties;
  descriptionStyle?: CSSProperties;
  leftItem?: ReactNode;
  rightItem?: ReactNode;
  itemAlign?: string;
  titleComponent?: React.ElementType;
  descrptionComponent?: React.ElementType;
  titleVariant?: LBTTypographyStyles;
  datatestid?: string;
}

export default function LBTListItem({
  title,
  description,
  leftItem,
  rightItem,
  titleStyle,
  descriptionStyle,
  itemAlign,
  titleComponent,
  descrptionComponent,
  titleVariant = 'listTitle',
  datatestid = '',
  ...props
}: Props) {
  return (
    <StyledListItem
      disablePadding
      datatestid={`lbt-list-item-${datatestid}`}
      data-track={datatestid}
      style={{
        cursor: props.onClick ? 'pointer' : undefined,
        padding: '8px 16px',
      }}
      {...props}
    >
      {leftItem && (
        <Box alignSelf={itemAlign} marginRight="36px">
          {leftItem}
        </Box>
      )}
      <ListItemText
        sx={{ margin: '0px', textAlign: 'left' }}
        primary={
          <LBTLabel
            variant={titleVariant}
            component={titleComponent}
            textAlign="left"
            style={titleStyle}
            datatestid={datatestid}
          >
            {title}
          </LBTLabel>
        }
        secondary={
          description ? (
            <LBTLabel
              variant="listDescription"
              color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
              component={descrptionComponent}
              style={descriptionStyle}
              textAlign="left"
              datatestid={datatestid}
            >
              {description}
            </LBTLabel>
          ) : undefined
        }
      />
      {rightItem && (
        <Box alignSelf={itemAlign} marginLeft="36px">
          {rightItem}
        </Box>
      )}
    </StyledListItem>
  );
}
