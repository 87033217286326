import { CallbackInterface } from 'recoil';
import {
  Domain,
  GetAllDomain,
  GetAllDomainKeys,
  Id,
  PatchDomain,
  Post,
  Put,
} from '../types';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  domainsLoaderState,
  domainLoaderState,
  domainState,
  domainsState,
  domainKeysLoaderState,
  domainKeysState,
} from '../state';
import {
  postDomain,
  getDomainsByKey,
  getAllDomains,
  putDomain,
  deleteDomain,
  getAllDomainKeys,
  getDomainById,
  patchDomain,
  getParentDomain,
} from '../actions';

export const postDomainCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Domain>) => {
    set(domainLoaderState, true);
    let res = undefined;
    try {
      res = await postDomain(params);
      set(domainState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Domain caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainLoaderState, false);
    return res;
  };

export const getDomainByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(domainLoaderState, true);
    let res = undefined;
    try {
      res = await getDomainById(params);
      set(domainState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainLoaderState, false);
    return res;
  };

export const getParentDomainCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(domainKeysLoaderState, true);
    let res = undefined;
    try {
      res = await getParentDomain(params);
    } catch (error: any) {}
    set(domainKeysLoaderState, false);
    return res;
  };

export const getDomainsByKeyCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(domainsLoaderState, true);
    let res = undefined;
    try {
      res = await getDomainsByKey(params);
      set(domainsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainsLoaderState, false);
    return res;
  };

export const getAllDomainsCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetAllDomain) => {
    set(domainsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllDomains(params);
      set(domainsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainsLoaderState, false);
    return res;
  };

export const getAllDomainKeysCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetAllDomainKeys) => {
    set(domainKeysLoaderState, true);
    let res = undefined;
    try {
      res = await getAllDomainKeys(params);
      set(domainKeysState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainKeysLoaderState, false);
    return res;
  };

export const putDomainCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Domain>) => {
    set(domainLoaderState, true);
    let res = undefined;
    try {
      res = await putDomain(params);
      set(domainState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Domain modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainLoaderState, false);
    return res;
  };

export const patchDomainCallback =
  ({ set }: CallbackInterface) =>
  async (params: PatchDomain) => {
    set(domainKeysLoaderState, true);
    let res = undefined;
    try {
      res = await patchDomain(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Domain modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainKeysLoaderState, false);
    return res;
  };

export const deleteDomainCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(domainLoaderState, true);
    let res = undefined;
    try {
      res = await deleteDomain(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Domain cancellato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(domainLoaderState, false);
    return res;
  };
