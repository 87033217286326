import { useNavigate, useParams } from 'react-router-dom';
import {
  COLORS,
  LBTButton,
  QuestionaryContext,
} from '@laborability/components';
import { useRecoilValue } from 'recoil';
import { questionFlowsState } from '@laborability/commons';
import Page from '@laborability/components/src/components/Questionary/Page';

export function PagePreview() {
  const navigate = useNavigate();
  const { flow_id, page_id } = useParams();
  const flows = useRecoilValue(questionFlowsState);
  const flow = flows.find(flow => flow.id === Number(flow_id));

  if (!page_id || !flow) {
    navigate(-1);
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
        zIndex: 10000,
      }}
    >
      <div style={{ padding: '20px' }}>
        <LBTButton onClick={() => navigate(-1)} variant="contained">
          Indietro
        </LBTButton>
      </div>
      <QuestionaryContext.Provider
        value={{
          pageId: Number(page_id),
          setPageId: () => {},
          step: flow.step!,
          setStep: () => {},
        }}
      >
        <Page flow={flow} isDemo />
      </QuestionaryContext.Provider>
    </div>
  );
}
