import { Box, InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import IconWarningComponent from '../Icons/IconWarningComponent';
import { COLORS } from '../../utils/Colors';
import { IconsSize, IconsStyle } from '../../enums';

export type LBTTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  datatestid?: string;
  onChange: (val?: string) => void;
};

const StyledTextField = styled(TextField)<
  TextFieldProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const LBTTextField = forwardRef<HTMLInputElement, LBTTextFieldProps>(
  (
    {
      startIcon,
      endIcon,
      datatestid = '',
      onChange,
      disabled,
      ...props
    }: LBTTextFieldProps,
    ref,
  ) => {
    return (
      <StyledTextField
        inputRef={ref}
        fullWidth
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : undefined,
          endAdornment: endIcon ? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ) : undefined,
          style: {
            backgroundColor: disabled
              ? COLORS.getInstance().BW_GREYS_IPERLIGHT
              : undefined,
          },
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        onChange={e => onChange?.(e.target.value)}
        helperText={
          props.error ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IconWarningComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
              {props.helperText}
            </Box>
          ) : (
            props.helperText
          )
        }
        disabled={disabled}
        {...props}
      />
    );
  },
);

export default LBTTextField;
