import { ReactNode, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../utils';
import LBTLabel from '../Label';

interface BarProps {
  currentValue: number;
  maxValue: number;
  orientation: 'vertical' | 'horizontal';
}
interface Props {
  label: string;
  step: number;
  stepsNumber: number;
  item: Omit<BarProps, 'orientation'>;
  orientation: 'vertical' | 'horizontal';
}

export function ProgressIndicatorBar({
  currentValue,
  maxValue,
  orientation,
}: BarProps) {
  return (
    <div
      style={{
        height: orientation === 'horizontal' ? '12px' : undefined,
        width: orientation === 'vertical' ? '12px' : undefined,
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
        flex: currentValue && currentValue !== maxValue ? 2 : 1,
        backgroundColor: currentValue
          ? COLORS.getInstance().PRIMARY_IPERLIGHT
          : COLORS.getInstance().WHITE,
        border: `1px solid ${COLORS.getInstance().PRIMARY_IPERLIGHT}`,
        borderRadius: '6px',
      }}
    >
      {!!currentValue && (
        <div
          style={{
            position: 'relative',
            height:
              orientation === 'horizontal'
                ? '12px'
                : `${(currentValue * 100) / maxValue}%`,
            width:
              orientation === 'vertical'
                ? '12px'
                : `${(currentValue * 100) / maxValue}%`,
            inset: 0,
            backgroundColor: COLORS.getInstance().PRIMARY_MAIN,
            minWidth: orientation === 'vertical' ? '12px' : undefined,
            minHeight: orientation === 'vertical' ? '12px' : undefined,
            borderRadius: '6px',
          }}
        />
      )}
    </div>
  );
}

export default function ProgressIndicatorWidget({
  label,
  step,
  stepsNumber,
  item,
  orientation,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState(0);

  const getBars = () => {
    let bars: ReactNode[] = [];
    for (let i = 1; i <= stepsNumber; i++) {
      if (i < step)
        bars = [
          ...bars,
          <ProgressIndicatorBar
            key={i}
            currentValue={1}
            maxValue={1}
            orientation={orientation}
          />,
        ];
      else if (i > step)
        bars = [
          ...bars,
          <ProgressIndicatorBar
            key={i}
            currentValue={0}
            maxValue={1}
            orientation={orientation}
          />,
        ];
      else
        bars = [
          ...bars,
          <ProgressIndicatorBar
            key={i}
            currentValue={item.currentValue}
            maxValue={item.maxValue + 1}
            orientation={orientation}
          />,
        ];
    }
    return bars;
  };

  useEffect(() => {
    setSize(containerRef.current?.offsetHeight ?? 0);
  }, [containerRef.current?.offsetHeight]);

  return (
    <div
      ref={containerRef}
      style={{
        width: orientation === 'horizontal' ? '100%' : undefined,
        height: orientation === 'vertical' ? '100%' : undefined,
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'column' : 'row-reverse',
        gap: '6px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          marginTop:
            orientation === 'vertical'
              ? `calc(${(size - 4 * (stepsNumber - 1)) / (stepsNumber + 1) + 4}px * ${step - 1})`
              : undefined,
        }}
      >
        <LBTLabel
          variant="bodyText"
          color={COLORS.getInstance().BW_GREYS_MEDIUM_DARK}
          textAlign="left"
        >
          Passo {step}/{stepsNumber}
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="left">
          {label}
        </LBTLabel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          gap: '4px',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {getBars()}
      </div>
    </div>
  );
}
