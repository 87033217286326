import { useBreakpoint } from '@laborability/commons';
import Amaca from './Amaca';
import Astronaut from './Astronaut';
import Error404 from './Error404';
import Family from './Family';
import MyBonus from './MyBonus';
import TurtleAndBunny from './TurtleAndBunny';
import KeyDoor from './KeyDoor';
import Puzzle from './Puzzle';
import Telescope from './Telescope';
import PigAtBeach from './PigAtBeach';
import LaborabilityLogo from './LaborabilityLogo';
import ManWithMagnifyingGlass from './ManWithMagnifyingGlass';
import MoneyLoan from './MoneyLoan';
import HasBonus from './HasBonus';
import HasNoBonus from './HasNoBonus';
import ChasingMoney from './ChasingMoney';
import { CSSProperties } from 'react';

export type ImageType =
  | 'amaca'
  | 'astronaut'
  | 'error404'
  | 'family'
  | 'myBonus'
  | 'turtleAndBunny'
  | 'keyDoor'
  | 'puzzle'
  | 'telescope'
  | 'pigAtBeach'
  | 'laborabilityLogo'
  | 'manWithMagnifyingGlass'
  | 'moneyLoan'
  | 'hasBonus'
  | 'hasNoBonus'
  | 'chasingMoney';

const Images = {
  amaca: Amaca,
  astronaut: Astronaut,
  error404: Error404,
  family: Family,
  myBonus: MyBonus,
  turtleAndBunny: TurtleAndBunny,
  keyDoor: KeyDoor,
  puzzle: Puzzle,
  telescope: Telescope,
  pigAtBeach: PigAtBeach,
  laborabilityLogo: LaborabilityLogo,
  manWithMagnifyingGlass: ManWithMagnifyingGlass,
  moneyLoan: MoneyLoan,
  hasBonus: HasBonus,
  hasNoBonus: HasNoBonus,
  chasingMoney: ChasingMoney,
};

export interface ImageProps {
  height: number | string;
  width: number | string;
}

interface Props extends Partial<ImageProps> {
  image: ImageType;
  size?: number | string;
  style?: CSSProperties;
}

export default function LBTImage({ image, size, height, width, style }: Props) {
  const { isDesktop } = useBreakpoint();
  const defaultSize = isDesktop ? 300 : 200;
  const SelectedImage = Images[image];

  return (
    <div
      style={{
        maxWidth: isDesktop ? '300px' : '200px',
        width: '100%',
        ...style,
      }}
    >
      <SelectedImage
        height={height ?? size ?? defaultSize}
        width={width ?? size ?? defaultSize}
      />
    </div>
  );
}
